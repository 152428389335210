// ** React, Next & Mui
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { Stack, Avatar, Box, CardMedia, Chip, IconButton, Tooltip, useTheme, Typography, Button, useMediaQuery } from '@mui/material';
import { IconAward, IconMessage2, IconRepeat, IconThumbUp } from '@tabler/icons-react';
import { formatDistance } from 'date-fns';

// ** Context
import { useLens } from '../../context/lens';
import { useModalsActions } from '@/context/modals';
import { useSession } from '@/context/session';

// ** Components
import BlankCard from '../shared/BlankCard';
import ReactPlayer from 'react-player';
import { TruncateText } from '../shared/TruncateText';

// ** Utils
import { AnyPublicationFragment, isMirrorPublication } from '@lens-protocol/client';
import { getAvatarUrl } from '../../utils';
import { NotConnectedCase } from '../modals/not-connected';
import { CollectCriteria } from '@/utils/constants/types';
import { getPublicationData } from '@/helpers/getPublicationData';
interface Props {
  post: AnyPublicationFragment;
  category?: string;
}
export const FeedPostCard = ({
  post,
  category
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [collects, setCollects] = useState(0);
  const [isCollected, setIsCollected] = useState(false);
  const [collectCriteria, setCollectCriteria] = useState<CollectCriteria | null>();
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDarkMode = theme.palette.mode === 'dark';
  const {
    like,
    unlike,
    fetchCollectCriteria
  } = useLens();
  const {
    open
  } = useModalsActions();
  const {
    notLoggedIn,
    connectedNotLogged,
    isFarcasterUser
  } = useSession();
  const {
    by
  } = post;
  const isMirror = isMirrorPublication(post);
  const targetPublication = isMirror ? post.mirrorOn : post;
  const {
    metadata,
    profilesMentioned
  } = targetPublication;
  const filteredContent = getPublicationData(metadata)?.content || '';
  const filteredAttachments = getPublicationData(metadata)?.attachments || [];
  const filteredAsset = getPublicationData(metadata)?.asset;
  const showQuote = targetPublication.__typename === 'Quote';
  const mirroredBy = isMirror ? {
    name: post.by.metadata?.displayName || `@${post.by.handle?.localName}`,
    avatar: getAvatarUrl(post.by)
  } : null;
  const isMomokaPost = Boolean(post.momoka);
  useEffect(() => {
    getContentPost();
    return cleanState;
  }, [post]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const cleanState = () => {
    setIsLoading(true);
    setLikes(0);
    setIsLiked(false);
    setCollects(0);
    setIsCollected(false);
    setCollectCriteria(null);
  };
  const getContentPost = async () => {
    try {
      setIsLoading(true);
      if (post.__typename === 'Post') {
        const {
          stats,
          operations,
          openActionModules
        } = post;
        setLikes(stats?.upvotes);
        setCollects(stats?.collects);
        setIsCollected(operations.hasCollected.value);
        if (operations.hasUpvoted) setIsLiked(true);
        if (openActionModules.length > 0) {
          const collectData = await fetchCollectCriteria(post.id);
          setCollectCriteria(collectData);
        }
      }
    } catch (error) {
      console.log('Error getting post content', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleLikeClick = async () => {
    if (!post || isFarcasterUser) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    if (!isLiked) {
      await like(post?.id);
      setIsLiked(true);
      setLikes(prev => prev + 1);
    } else {
      await unlike(post?.id);
      setIsLiked(false);
      setLikes(prev => prev - 1);
    }
  };
  const handleCommentClick = async () => {
    if (!post || isFarcasterUser) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    open('comment', {
      publicationId: post.id,
      isMomokaPub: isMomokaPost
    });
  };
  const handleCollectClick = async () => {
    if (!post || isFarcasterUser) return;
    if (notLoggedIn) {
      open('notConnected', {
        modalCase: NotConnectedCase.NotConnected
      });
      return;
    }
    if (connectedNotLogged) {
      open('notConnected', {
        modalCase: NotConnectedCase.PendingLogIn
      });
      return;
    }
    open('collect', {
      publicationId: post.id as string,
      onSuccess: () => {
        setIsCollected(true);
        setCollects(prev => prev + 1);
      }
    });
  };

  /*************************************************
   *                    Render                     *
   *************************************************/

  const postDetailPath = post ? `/profile/post/${post.id}` : '/';
  const userProfilePath = by ? `/profile/${by.id}` : '/';
  const timeAgo = useMemo(() => {
    const createdAt = new Date(post.createdAt);
    const now = new Date();
    return formatDistance(createdAt, now);
  }, [post.createdAt]);
  const CollectButton = () => {
    const canCollect = collectCriteria?.canCollect === 'YES';
    if (isFarcasterUser) return null;
    if (isCollected) {
      return <Button sx={{
        mt: '0.6em'
      }} disabled={isCollected} color="secondary" variant="contained" size="small" onClick={handleCollectClick}>
          Collected
        </Button>;
    } else if (canCollect) {
      return <Button sx={{
        mt: '0.6em'
      }} disabled={!canCollect} color="secondary" variant="contained" size="small" onClick={handleCollectClick}>
          Collect
        </Button>;
    }
    return null;
  };
  return <BlankCard data-sentry-element="BlankCard" data-sentry-component="FeedPostCard" data-sentry-source-file="PostCard.tsx">
      <Box display="flex" flexDirection="column" alignItems="center" height={isXsScreen ? undefined : '32em'} justifyContent={{
      xs: 'center',
      sm: 'space-between'
    }} p={2} bgcolor={theme.palette.grey[200]} sx={{
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
    }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
        <Box display="flex" justifyContent={{
        xs: 'start',
        sm: 'center'
      }} width="100%" sx={{
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[300],
        padding: '0.5rem'
      }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
          <Stack direction="row" alignItems="center" spacing={1} data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
            <Tooltip title={by.handle?.localName} data-sentry-element="Tooltip" data-sentry-source-file="PostCard.tsx">
              <Link href={`/profile/${by.id}`} style={{
              textDecoration: 'none',
              color: 'inherit'
            }} data-sentry-element="Link" data-sentry-source-file="PostCard.tsx">
                <Avatar src={getAvatarUrl(by)} alt={by.handle?.localName} sx={{
                width: {
                  xs: 40,
                  sm: 30
                },
                height: {
                  xs: 40,
                  sm: 30
                },
                '&:hover': {
                  filter: 'brightness(0.5)',
                  transition: '0.2s',
                  border: '2px solid transparent'
                }
              }} data-sentry-element="Avatar" data-sentry-source-file="PostCard.tsx" />
              </Link>
            </Tooltip>
            <Box mb="1.25rem" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
              <Typography variant="subtitle1" fontWeight={700} data-sentry-element="Typography" data-sentry-source-file="PostCard.tsx">
                <span className="name">
                  {by.metadata?.displayName || `@${by.handle?.localName}`}
                </span>
                <span style={{
                color: theme.palette.success.main
              }}>
                  {' '}
                  Posted
                </span>
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border={`2px dotted ${theme.palette.divider}`} my={1} width="100%" minHeight={isXsScreen ? '350px' : '250px'} sx={{
        flex: 1
      }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
          <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="90%" sx={{
          backgroundColor: theme.palette.grey[100],
          padding: '0.5rem',
          position: 'relative'
        }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
            <Link href={postDetailPath} style={{
            color: 'inherit',
            textDecoration: 'none'
          }} data-sentry-element="Link" data-sentry-source-file="PostCard.tsx">
              <Box my={{
              xs: 1,
              sm: 0.2
            }} width="100%" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
                <TruncateText text={filteredContent} postId={post.id} cleanNewLines lines={filteredAsset ? 2 : 8} mentions={profilesMentioned} data-sentry-element="TruncateText" data-sentry-source-file="PostCard.tsx" />
              </Box>
            </Link>

            {filteredAsset?.type === 'Image' && <Link href={`/profile/post/${post.id}`}>
                <CardMedia component="img" image={filteredAsset.uri} alt="post image" sx={{
              width: '100%',
              height: isXsScreen ? 225 : 120,
              objectFit: 'cover',
              borderRadius: '6px'
            }} />
              </Link>}

            {filteredAsset?.type === 'Video' && <Box sx={{
            width: '100%',
            height: isXsScreen ? 225 : 120
          }}>
                <ReactPlayer url={filteredAsset.uri} controls={true} width="100%" height="100%" />
              </Box>}
            <CollectButton data-sentry-element="CollectButton" data-sentry-source-file="PostCard.tsx" />
            <Box sx={{
            position: 'absolute',
            bottom: 8,
            right: 8
          }} data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
              <Typography variant="caption" color="textSecondary" data-sentry-element="Typography" data-sentry-source-file="PostCard.tsx">
                {timeAgo} ago
              </Typography>
            </Box>
          </Box>

          <Box p={0.5} mt={0.1} display="flex" flexDirection="column" alignItems="center" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
            {category && <Chip label={category} size="small" sx={{
            color: theme.palette.grey[900],
            backgroundColor: isDarkMode ? '#d4b715' : '#faeb96',
            fontWeight: 'normal',
            overflow: 'hidden'
          }} />}
          </Box>
        </Box>

        <Box width="100%" mt="auto" px={1} justifyContent="center" display="flex" data-sentry-element="Box" data-sentry-source-file="PostCard.tsx">
          <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
            <Stack direction="row" alignItems="center" spacing={0.5} data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
              <Tooltip title="Like" data-sentry-element="Tooltip" data-sentry-source-file="PostCard.tsx">
                <IconButton size="small" color={isLiked ? 'primary' : 'inherit'} onClick={handleLikeClick} data-sentry-element="IconButton" data-sentry-source-file="PostCard.tsx">
                  <IconThumbUp size={25} data-sentry-element="IconThumbUp" data-sentry-source-file="PostCard.tsx" />
                </IconButton>
              </Tooltip>
              <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="PostCard.tsx">{likes}</Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.5} data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
              <Tooltip title="Comment" data-sentry-element="Tooltip" data-sentry-source-file="PostCard.tsx">
                <IconButton size="small" onClick={handleCommentClick} data-sentry-element="IconButton" data-sentry-source-file="PostCard.tsx">
                  <IconMessage2 size={25} data-sentry-element="IconMessage2" data-sentry-source-file="PostCard.tsx" />
                </IconButton>
              </Tooltip>
              <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="PostCard.tsx">
                {post.stats?.comments || 0}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={0.5} data-sentry-element="Stack" data-sentry-source-file="PostCard.tsx">
              <Tooltip title="Collect" data-sentry-element="Tooltip" data-sentry-source-file="PostCard.tsx">
                <IconButton size="small" color={isCollected ? 'primary' : 'inherit'} onClick={handleCollectClick} data-sentry-element="IconButton" data-sentry-source-file="PostCard.tsx">
                  <IconAward size={25} data-sentry-element="IconAward" data-sentry-source-file="PostCard.tsx" />
                </IconButton>
              </Tooltip>
              <Typography variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="PostCard.tsx">{collects}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </BlankCard>;
};